import React from "react"

const reviewsList = [
  {
    content:
      "ReviewVio is the only company that successfully helped us clean up our online reputation - from removing negative reviews to increasing our positive online reviews.",
    // image: require("../images/thumbnail-1@2x.png"),
    image:
      "https://reviewvio.com/wp-content/uploads/2021/01/Aurora-Company-logo402x-1024x990.png",
    name: "Josh T.",
    position: "CDO, Aurora Capital",
  },
  {
    content:
      "The team at ReviewVio did a great job for us. We had three bad reviews online thatwere damaging our online reputation. They got to work and one by one the reviews were removed.",
    // image: require("../images/thumbnail-1@2x.png"),
    image: "https://reviewvio.com/wp-content/uploads/2021/01/fb-2222.png",
    name: "Bernard G.",
    position: "Owner, Fairbanks Pharmacy & Med Spa",
  },
]

const Reviews = () => {
  return (
    <div className="reviews-wrapper">
      {reviewsList.map((item, i) => {
        return (
          <div key={i} className="review-content">
            <p className="text">“{item.content}”</p>
            <div className="reviewer-info">
              {item.image && (
                <img
                  src={item.image}
                  alt={item.name}
                  className="reviewer-image"
                />
              )}
              <div className="reviewer-name">
                <p className="text-bold">{item.name}</p>
                {item.position && <p className="text-sm">{item.position}</p>}
              </div>
            </div>
          </div>
        )
      })}
      <div className="other-content">
        <p className="text">
          Moving from a 3 Star to a 5 Star increases revenue by 18%! So if your
          gross sales are $1 Million per year, you’re missing out on an extra
          $180,000 in revenue!
        </p>
        <img
          src={require("../images/harvard@2x.png")}
          alt="Harvard Business Review"
        />
        <p className="text">
          <span>Remove negative reviews</span> <span>permanently</span> and
          prevent 90% of future negative reviews
        </p>
        <p className="text">
          <span>Remove</span> negative content online from major review sites
          such as Yelp, Google, Facebook, Homeadvisor, Tripadvisor and more
        </p>
        <p className="text">
          <span>Prevent</span> 90% of new negative reviews from occurring and…
        </p>
        <p className="text">
          <span>Capture</span> 100% of new positive reviews via text and email
          in just three clicks!
        </p>
      </div>
    </div>
  )
}

export default Reviews
